import axios from 'axios';
import EventBus from 'eventing-bus';
import { all, takeEvery, call, put } from 'redux-saga/effects';

import {
  signInData, setUser, setCountryLeaderBoard, setPlayerLeaderBoard,
  getCountryLeaderBoard as getCountryBoard, getPlayerLeaderBoard as getPlayerBoard
} from "../actions/Auth";

function* signIn({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/loginWithWallet', payload });
  // if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  if (response) yield put(signInData(response['data']['body']));
}

function* setScore({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/setScore', payload });
  // if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  if (response) {
    yield put({ type: 'GET_USER', payload: response['data']['body'] });
    yield put(getCountryBoard());
    yield put(getPlayerBoard());
  };
}

function* getUser({ payload }) {
  const { error, response } = yield call(getCall, `/users/${payload}`);
  // if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  if (response) yield put(setUser(response['data']['body']));
}

function* setCountryBoard({ payload }) {
  const { error, response } = yield call(postCall, { path: '/users/setCountryBoard', payload });
  // if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  // if (response) yield put(setUser(response['data']['body']));
}

function* getCountryLeaderBoard() {
  const { error, response } = yield call(getCall, `/users/countryLeadboard`);
  // if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  if (response) yield put(setCountryLeaderBoard(response['data']['body']));
}

function* getPlayerLeaderBoard() {
  const { error, response } = yield call(getCall, `/users/playersLeaderBoard`);
  if (error) EventBus.publish('error', error['response']?.['data']?.['message']);
  if (response) yield put(setPlayerLeaderBoard(response['data']['body']));
}

function* actionWatcher() {
  yield takeEvery('SIGN_IN', signIn);
  yield takeEvery('GET_USER', getUser);
  yield takeEvery('SET_SCORE', setScore);
  yield takeEvery('SET_COUNTRY_BOARD', setCountryBoard);
  yield takeEvery('GET_PLAYER_LEADERBOARD', getPlayerLeaderBoard);
  yield takeEvery('GET_COUNTRY_LEADERBOARD', getCountryLeaderBoard);
};

export default function* rootSaga() {
  yield all([actionWatcher()]);
};

function postCall({ path, payload }) {
  return axios
    .post(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function getCall(path) {
  return axios
    .get(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function deleteCall(path) {
  return axios
    .delete(path)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};

function putCall({ path, payload }) {
  return axios
    .put(path, payload)
    .then(response => ({ response }))
    .catch(error => {
      if (error.response.status === 401) EventBus.publish("tokenExpired");
      return { error };
    });
};
