
import EventBus from 'eventing-bus';
import { useDispatch } from 'react-redux';
import Error from '@material-ui/icons/Error';
import React, { useEffect, Suspense } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import CheckCircle from '@material-ui/icons/CheckCircle';
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";


import '../static/css/style.css';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'font-awesome/css/font-awesome.min.css';
import "react-toastify/dist/ReactToastify.css";


import Home from './Home/index';
import Preloader from '../components/preloader';
import { signOut } from '../store/actions/Auth';

const App = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    EventBus.on('tokenExpired', () => dispatch(signOut()));
    EventBus.on('info', (e) => toast.info(() => <div> <Error /> {e}</div>));
    EventBus.on('error', (e) => toast.error(() => <div> <Error /> {e}</div>));
    EventBus.on('success', (e) => toast.success(() => <div> <CheckCircle /> {e}</div>));
  }, []);

  return (
    <div>
      <Suspense fallback={<Preloader />}>
        <ToastContainer closeOnClick position="bottom-left" />
        <Router>
          <Switch>
            <Route exact path='/' component={props => <Home {...props} />} />
            <Route exact path='/Home' component={props => <Home {...props} />} />
          </Switch>
        </Router>
      </Suspense>
    </div>
  );
}

export default App;