export const signIn = (data) => ({
  type: 'SIGN_IN',
  payload: data,
});

export const signInData = (data) => ({
  type: 'SIGN_IN_DATA',
  payload: data,
});

export const signOut = () => ({
  type: 'SIGN_OUT'
});

export const toggleLoader = (data) => ({
  type: 'TOGGLE_LOADER',
  payload: data,
});

export const setScoreAPI = (data) => ({
  type: 'SET_SCORE',
  payload: data,
});

export const getUser = (data) => ({
  type: 'GET_USER',
  payload: data,
});

export const setUser = (data) => ({
  type: 'SET_USER',
  payload: data,
});

export const setCountryInfo = (data) => ({
  type: 'SET_COUNTRY_INFO',
  payload: data,
});

export const setCountryBoard = (data) => ({
  type: 'SET_COUNTRY_BOARD',
  payload: data,
});

export const getCountryLeaderBoard = (data) => ({
  type: 'GET_COUNTRY_LEADERBOARD',
  payload: data,
});

export const setCountryLeaderBoard = (data) => ({
  type: 'SET_COUNTRY_LEADERBOARD',
  payload: data,
});

export const getPlayerLeaderBoard = (data) => ({
  type: 'GET_PLAYER_LEADERBOARD',
  payload: data,
});

export const setPlayerLeaderBoard = (data) => ({
  type: 'SET_PLAYER_LEADERBOARD',
  payload: data,
});