import { setToken } from "../axios";

let initialState = {
  user: {},
  score: 0,
  isLoader: false,
  countryInfo: {},
  auth: localStorage.getItem('auth'),
  countryLeaderBoard: [],
  playerLeaderBoard: [],
  publicAddress: localStorage.getItem('publicAddress'),
}

const Auth = (state = initialState, { type, payload }) => {
  switch (type) {

    case 'SIGN_IN_DATA':
      setToken(payload['token']);
      localStorage.setItem('auth', payload['token']);
      localStorage.setItem('publicAddress', payload['publicAddress']);
      return {
        ...state,
        auth: payload['token'],
        publicAddress: payload['publicAddress']
      };

    case 'TOGGLE_LOADER':
      return {
        ...state,
        isLoader: payload,
      };

    case 'SET_USER':
      return {
        ...state,
        user: payload,
        score: payload['totalScore'],
      };

    case 'SET_COUNTRY_INFO':
      return {
        ...state,
        countryInfo: payload
      };

    case 'SET_COUNTRY_LEADERBOARD':
      return {
        ...state,
        countryLeaderBoard: payload,
      };

    case 'SET_PLAYER_LEADERBOARD':
      return {
        ...state,
        playerLeaderBoard: payload,
      };

    case 'SIGN_OUT':
      localStorage.removeItem('auth');
      localStorage.removeItem('publicAddress');
      return {
        ...state,
        auth: null,
        publicAddress: null
      };

    default:
      return state;
  }
};

export default Auth;