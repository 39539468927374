import { render } from 'react-dom';
import { logger } from 'redux-logger';
import React, { useMemo } from 'react';
import { Provider } from 'react-redux';
import createSagaMiddleware from 'redux-saga';
import { clusterApiUrl } from '@solana/web3.js';
import { createStore, applyMiddleware } from 'redux';
import { WalletModalProvider } from '@solana/wallet-adapter-react-ui';
import { PhantomWalletAdapter } from '@solana/wallet-adapter-phantom';
import { ConnectionProvider, WalletProvider } from '@solana/wallet-adapter-react';

import './index.css';
import App from './app';
import rootSaga from './store/sagas';
import rootReducer from './store/reducers';
import reportWebVitals from './reportWebVitals';

const sagaMiddleware = createSagaMiddleware();
const store = createStore(rootReducer, applyMiddleware(sagaMiddleware, logger));
sagaMiddleware.run(rootSaga);

const AppWithWalletConnect = () => {
  const endpoint = clusterApiUrl("devnet");
  const wallets = useMemo(() => [new PhantomWalletAdapter()], []);

  return (
    <ConnectionProvider endpoint={endpoint}>
      <WalletProvider wallets={wallets}>
        <WalletModalProvider>
          <App />
        </WalletModalProvider>
      </WalletProvider>
    </ConnectionProvider>
  );
}


render(
  <Provider store={store}>
    <AppWithWalletConnect />
  </Provider>,
  document.getElementById('root')
);
if (module.hot) { module.hot.accept(App); }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
